













































































































































































import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { Pagination } from "@/models/interface-v2/common.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { CodeTranslator } from "@/models/interface-v2/code-translator.interface";
import { codeTranslatorServices } from "@service/code-translator.service";
import Vue from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "TranslatorBatchList",
  components: {
    CSelectTranslatorType: () => import("@/components/shared/select-translator-type/CSelectTranslatorType.vue")
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      queryParams: {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        search: "",
      },
      dtList: {} as Pagination<CodeTranslator[]>,
      loading: {
        table: false,
      },
      form: {
        code: "",
        key: "",
        type: "",
      },
      rules: {
        code: [{ required: false, }],
        key: [{ required: false, }],
        type: [{ required: false, }],
      }
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "code-translator" && x.privilege.create && x.privilege.update);
    },
  },
  created() {
    this.getList(this.queryParams);
  },
  methods: {
    handleClear(): void {
      this.form = {
        code: "",
        key: "",
        type: "",
      };
      this.queryParams.page = 0;
      this.queryParams.search = "";
    },
    handleFind(): void {
      const searchBy: string[] = [];
      if (this.form.code) searchBy.push(`code~*${this.form.code}*`);
      if (this.form.key) searchBy.push(`key~*${this.form.key}*`);
      if (this.form.type) searchBy.push(`type~${this.form.type}`);
      if (searchBy.length) {
        this.queryParams.search = searchBy.join("_AND_");
      }
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.getList(this.queryParams);
    },
    goToUpdate({ id }): void {
      this.$router.push({ name: "settings.translatorbatch.edit", params: { id } });
    },
    handleCreateNew(): void {
      this.$router.push({ name: "settings.translatorbatch.create" });
    },
    async getList(queryparams: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.table = true;
        const res = await codeTranslatorServices.getList(queryparams);
        this.dtList = res;
      } catch (error) {
        this.showErrorMessage();
      } finally {
        this.loading.table = false;
      }
    },
  }
});
